export const textData = {
    name: {
        translate: true,
        key: 'widgets.text.name'
    },
    type: 'text',
    category: 'other',
    icon: ['fas', 't'],
    defaultConfig: {
        w: 3,
        h: 3,
    },
    defaultContent: {
        color: 'white',
        mainTitle: {
            translate: true,
            key: 'widgets.text.defaultContent.mainTitle',
        },
        preTitle: {
            translate: true,
            key: 'widgets.text.defaultContent.preTitle',
        },
        text: {
            translate: true,
            key: 'widgets.text.defaultContent.text',
        },
        textAlignment: 'center',
    },
    hasWidgetData: false,
    loadInitialData: false
}
