export const imageData = {
    name: {
        translate: true,
        key: 'widgets.image.name'
    },
    type: 'image',
    category: 'other',
    icon: ['fas', 'image'],
    defaultConfig: {
        w: 3,
        h: 3,
    },
    defaultContent: {
        image: {
            translate: true,
            key: '',
        },
        displayType: 'contain'
    },
    hasWidgetData: false,
    loadInitialData: false
}
