export const goalData = {
    type: 'goal',
    name: {
        translate: true,
        key: 'widgets.goal.name'
    },
    category: 'goals',
    icon: ['fas', 'trophy'],
    defaultConfig: {
        w: 4,
        h: 4,
    },
    defaultContent: {
        goal_id: null,
        confetti_enabled: false
    },
    hasWidgetData: true,
    loadInitialData: false
}

export function isReached(goal) {
    return goal.progress >= goal.goal
}
