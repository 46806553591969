import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useI18n} from 'vue-i18n';
import {$larafetch} from "~/utils/$larafetch";
import {useToast} from "vue-toastification";
import {useLinkedDashboardsStore} from "~/store/linkedDashboards";
import {isMobileDevice} from "~/composables/userDevice";

export const useDashboardStore = defineStore('dashboard', () => {
    const toast = useToast()
    const {t} = useI18n()
    const {$apiRoute} = useNuxtApp()
    const linkedDashboardsStore = useLinkedDashboardsStore()

    const loading = ref(false)
    const submitLoading = ref(false)
    const selectedWidgetNodeId = ref(null)
    const dashboard = ref({
        id: 0,
        name: t('dashboards.sidebar.settings.defaultName'),
        icon_name: '',
        is_public: false,
        home_visible: false,
        token: '',
        widgets: [],
        is_dirty: false,
    })

    const persistedWidgets = ref([])

    // set linkedDashboards when fetching data to make sure sidebarNavigation is not empty on page change
    const linkedDashboards = ref(null)

    const reset = () => {
        dashboard.value = {
            id: 0,
            name: t('dashboards.sidebar.settings.defaultName'),
            icon_name: '',
            is_public: false,
            home_visible: false,
            token: '',
            widgets: [],
            is_dirty: false,
        }
        selectedWidgetNodeId.value = null
        loading.value = false
    }

    /**
     * Determines if the dashboard has dirty widgets
     */
    const hasDirtyWidgets = computed(() => {
        return dashboard.value.widgets.some(widget => widget?.is_dirty)
    })

    /**
     * Determines if the dashboard has loading widgets
     */
    const hasLoadingWidgets = computed(() => {
        return dashboard.value.widgets.some(widget => widget?.loading)
    })

    /**
     * @param identifier {number|string} The dashboard id or token
     * @param mode {'view'|'edit'} The mode to use to fetch the dashboard
     * @returns {Promise<void>}
     */
    async function getDashboard(identifier, mode) {
        if (identifier === 0 && mode === 'edit') return
        loading.value = true

        const route = mode === 'edit' ? $apiRoute('spa.dashboards.show', {dashboard: identifier}) : $apiRoute('spa.frontend.dashboards.show', {dashboard_frontend: identifier})

        await $larafetch(route
        ).then(response => {
            dashboard.value = structuredClone(response)
            dashboard.value.is_dirty = false
            linkedDashboards.value = response.linked_dashboards
            persistedWidgets.value = structuredClone(response.widgets)
        }).catch(error => {
            toast.error(error.data?.message)
            navigateTo({path: '/dashboards'})
        }).finally(() => {
            loading.value = false
        })
    }

    async function saveDashboard(values = null, actions = {}) {
        submitLoading.value = true
        const isUpdate = !!dashboard.value.id

        const url = isUpdate ? $apiRoute('spa.dashboards.update', {dashboard: dashboard.value.id}) : $apiRoute('spa.dashboards.store')
        const body = {
            userHasMobileDevice: isMobileDevice(),
            ...dashboard.value,
            addedDashboards: linkedDashboardsStore.addedDashboards,
            removedDashboards: linkedDashboardsStore.removedDashboards
        }
        if (useRoute().query.mode === 'template') {
            isUpdate ? body['updateMode'] = 'updateTemplate' : body['storeMode'] = 'createTemplate';
        }

        await $larafetch(url, {
            method: isUpdate ? "PUT" : "POST",
            body: body
        }).then(async (response) => {
            toast.success(t('dashboards.sidebar.settings.saveSuccess'))

            dashboard.value = {
                ... structuredClone(response),
                widgets: structuredClone(response).widgets.map(widget => {
                    const oldWidget = dashboard.value.widgets.find(w => w.id === widget.id)
                    return {
                        ... oldWidget, // Preserve other properties of the existing widget like node_id
                        ... widget
                    }
                })
            }

            dashboard.value.is_dirty = false

            persistedWidgets.value = structuredClone(response.widgets)

            linkedDashboardsStore.fetchDashboards()
            linkedDashboardsStore.addedDashboards = [];
            linkedDashboardsStore.removedDashboards = [];

            if (!isUpdate) {
                if (useRoute().query.mode === 'template') {
                    await navigateTo({name: 'dashboards-id', params: {id: response.id}, query: {mode: 'template'}})
                } else {
                    await navigateTo({name: 'dashboards-id', params: {id: response.id}})
                }
            }
        }).catch(error => {
            if (actions && typeof actions.setErrors === 'function') {
                if (error.data?.errors) {
                    actions.setErrors(error?.data?.errors)
                }
            } else {
                console.log('setErrors is not a function')
            }
            if (error.data?.message) toast.error(error.data.message)
        }).finally(() => {
            submitLoading.value = false
        })
    }

    return {
        dashboard,
        loading,
        submitLoading,
        selectedWidgetNodeId,
        linkedDashboards,
        getDashboard,
        saveDashboard,
        reset,
        hasDirtyWidgets,
        hasLoadingWidgets,
        persistedWidgets
    }
})