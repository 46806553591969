import { ref, computed, onMounted, onUnmounted } from 'vue';

export const isMobileDevice = () => {
    const width = ref(window.innerWidth); // Reactive state for window width

    const updateWidth = () => {
        width.value = window.innerWidth; // Update width when the window is resized
    };

    // Computed property to check if the device is mobile based on width
    const isMobileDevice = computed(() => {
        return width.value < 1000;
    });

    // Set up event listeners for window resizing
    window.addEventListener('resize', updateWidth); // Listen for window resizing

    return isMobileDevice.value; // Return the computed property
}