export const goalOverviewData = {
    type: 'goalOverview',
    name: {
        translate: true,
        key: 'widgets.goalOverview.name'
    },
    category: 'goals',
    icon: ['fas', 'trophy'],
    defaultConfig: {
        w: 4,
        h: 4,
    },
    defaultContent: {
        goals: [],
        confetti_enabled: false
    },
    hasWidgetData: true,
    loadInitialData: false
}