import {useDashboardStore} from "~/store/dashboard";
import {$larafetch} from "@/utils/$larafetch";
import {useToast} from "vue-toastification";
import {storeToRefs} from "pinia";
import {ComputedRef, Ref} from "vue";

export interface Widget {
    id: number | string;
    node_id: number;
    name: string;
    type: string;
    config: object;
    content: any;
    data?: any;
    is_new?: boolean;
    is_dirty?: boolean;
    loading?: boolean;
}

/**
 * Get the widget by nodeId
 * @param nodeId The nodeId of the widget
 * @throws Error When no matching widgets are found
 */
export const useWidget = (nodeId: number | Ref<number>) => {
    const {dashboard} = storeToRefs(useDashboardStore())
    const widgets = computed(() => dashboard.value.widgets) as ComputedRef<Widget[]>

    return computed(() => {
        const id = toValue(nodeId)
        const foundWidget = widgets.value.find(w => w.node_id === id)
        if (!foundWidget) {
            throw new Error(`Widget with nodeId ${id} not found`)
        }
        return foundWidget
    })
}

/**
 * Get the widgets data
 * @param nodeId
 * @param boardToken
 * @param type
 * @param setLoading
 */
export const getData = async (nodeId: number, boardToken: string, type: string, setLoading = true) => {
    const {$apiRoute} = useNuxtApp() as any
    const toast = useToast()
    const widget = useWidget(nodeId)

    if (setLoading) widget.value.loading = true

    await $larafetch($apiRoute('spa.frontend.widgets.showData'), {
        method: 'GET',
        params: {
            type: type,
            content: widget.value?.content ?? null,
            boardToken: boardToken
        }
    }).then(response => {
        widget.value.data = {...response}
    }).catch(error => {
        toast.error(error.data?.message ?? error.message)
    }).finally(() => {
        if (setLoading && widget.value?.loading) widget.value.loading = false
    })
}