export const championData = {
    type: 'champion',
    name: {
        translate: true,
        key: 'widgets.champion.name'
    },
    category: 'team',
    icon: ['fas', 'medal'],
    defaultConfig: {
        w: 2,
        h: 5,
    },
    defaultContent: {
        subTitle: "Champion",
        userSelect: 'all',
        users: [],
        eventTypes: [],
        dataDisplay: 'sum',
        interval: 'all',
        color: 'primary',
    },
    hasWidgetData: true,
    loadInitialData: true
}
